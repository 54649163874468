@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h1,
  h2,
  h3,
  h4 {
    @apply font-bold;
  }
}

@layer components {
  .sticky-header {
    position: sticky;
    top: -1px; /* ➜ the trick */

    padding-top: 1px; /* ➜ compensate for the trick */
  }

  .sticky-header .logo {
    width: 0px;
    height: 0px;
    transition: 0.1s;
  }

  /* styles for when the header is in sticky mode */
  .sticky-header.is-sticky .logo {
    width: 50px;
    height: 50px;
  }

  .sticky-header.is-sticky ul li {
    @apply flex items-center py-6 pl-4 border-l;
  }
}

@layer utilities {
  .hover-gradient {
    @apply hover:bg-gradient-to-br from-neutral-100 via-transparent to-white;
  }
}

:root {
  --font-primary: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-secondary: serif;

  /* set base values */
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;

  --heading-line-height: 1.2em;
  --body-line-height: 1.5em;

  /* type scale */
  --text-xs: calc(1em / (var(--text-scale-ratio) * var(--text-scale-ratio)));
  --text-sm: calc(1em / var(--text-scale-ratio));
  --text-md: calc(1em * var(--text-scale-ratio));
  --text-lg: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xl: calc(
    1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-xxl: calc(
    1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio)
  );
  --text-xxxl: calc(
    1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio) * var(--text-scale-ratio)
  );

  /* spacing values */
  --space-unit: 1em;
  --space-xxs: calc(0.25 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));

  /* jaap */
  --color-pink: #f7c5f2;
  --color-lilac: #dbc5f7;
  --max-content-width: 860px;

  /* main colors */
  --color-primary: #4d84f4;
  /* --color-primary-light: color-mod(var(--color-primary) tint(15%));
    --color-primary-dark: color-mod(var(--color-primary) shade(15%));
    --color-primary-bg: color-mod(var(--color-primary) alpha(20%)); */

  --color-accent: #f5414f;
  /* --color-accent-light: color-mod(var(--color-accent) tint(15%));
    --color-accent-dark: color-mod(var(--color-accent) shade(10%));
    --color-accent-bg: color-mod(var(--color-accent) alpha(20%)); */

  --black: #1d1d21;
  --gray-10: #2e2e31;
  --gray-6: #7b7a7d;
  --gray-4: #a5a5a6;
  --gray-3: #bbbbbc;
  --gray-2: #d1d0d2;
  --gray-1: #e8e7e8;
  --white: white;

  /* feedback */
  --color-success: #88c459;
  --color-error: #f5414f;
  --color-warning: #ffd137;

  /* typography */
  --color-text: var(--gray-10);
  --color-text-heading: var(--gray-10);
  --color-text-subtle: var(--gray-6);
  --color-link: var(--color-primary);
  --color-link-visited: var(--color-primary-dark);
}
